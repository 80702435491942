import axios from "axios";
import keycloak from "./services/Keycloak";

const fetchClient = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL || "http://localhost:9090/api/v1.0",
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${keycloak.token}`;
    return config;
  });

  return instance;
};

export default fetchClient();