import React, { useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import { LOAD_EVENT_CANCEL, LOAD_EVENT_COMPLETE } from "../helpers/apolloQueries";
import { BASE_QUERY_OPTIONS, completeWorkOrder, loadWorkOrder, resetWorkOrder } from "../helpers/workOrderActions";


const useResourceActionMethods = props => {

  const [loadEventCancelQuery] = useLazyQuery(
    LOAD_EVENT_CANCEL,
    BASE_QUERY_OPTIONS
  );

  const [loadEventCompleteQuery] = useLazyQuery(
    LOAD_EVENT_COMPLETE,
    BASE_QUERY_OPTIONS
  );

  const loadWorkOrderMemoised = useCallback(() => {
    loadWorkOrder(
      props.workOrderNumberInputRef,
      props.setWorkOrderNumber,
      props.getWorkOrders
    );
  }, [
    props.workOrderNumberInputRef,
    props.setWorkOrderNumber,
    props.getWorkOrders,
  ]);

  const resetWorkOrderMemoised = useCallback(() => {
    return resetWorkOrder(
      props.loadEventId,
      loadEventCancelQuery,
      props.loadEventStartQuery,
      props.setWorkOrderNumber,
      props.getWorkOrders
    );
  }, [
    props.loadEventId,
    loadEventCancelQuery,
    props.loadEventStartQuery,
    props.setWorkOrderNumber,
    props.getWorkOrders,
  ]);

  const completeWorkOrderMemoised = useCallback(() => {
    return completeWorkOrder(
      props.workOrderNumberInputRef,
      props.setWorkOrderNumber,
      props.loadEventId,
      loadEventCompleteQuery,
      props.consumables,
      props.eventStartData,
      props.getWorkOrders,
      props.loadEventStartQuery
    );
  }, [
    props.workOrderNumberInputRef,
    props.setWorkOrderNumber,
    props.loadEventId,
    loadEventCompleteQuery,
    props.consumables,
    props.eventStartData,
    props.getWorkOrders,
    props.loadEventStartQuery,
  ]);

  return {
    loadWorkOrder: loadWorkOrderMemoised,
    resetWorkOrder: resetWorkOrderMemoised,
    completeWorkOrder: completeWorkOrderMemoised,
  };
};

export default useResourceActionMethods;
