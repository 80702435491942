import React, { useState, useMemo, useEffect } from "react";
import useTagReads from "../tag_reader/TagReads";

import { useQuery } from "@apollo/client";
import { retrieveDataFromStorage } from "./helpers/index";
import {
  GET_EQUIPMENT,
  GET_RETURNABLE_RESOURCES,
} from "./helpers/apolloQueries";
import { ResultTable } from "./Table";

const ReturnsBackend = () => {
  const locationFromStorage = retrieveDataFromStorage("location", [{}]);
  const [location] = useState(locationFromStorage);

  const resources = useQuery(GET_RETURNABLE_RESOURCES);

  const { tags } = useTagReads(location.Identifier);

  const equipmentQuery = useQuery(GET_EQUIPMENT, {
    variables: { tagList: tags || [] },
  });

  const [returnables, setReturnables] = useState([]);
  const [workOrderColumns, setWorkOrderColumns] = useState([]);

  useMemo(() => {
    if (resources.data?.tblResources?.items) {
      const workOrderResources = resources.data.tblResources.items.map(
        resource => {
          return {
            ...resource,
            Balance: 0,
          };
        }
      );

      setReturnables(workOrderResources);
    }
  }, [resources.data && resources.data.tblResources]);

  // update our returnables table data with tag read data
  useEffect(() => {
    const queryData =
      (equipmentQuery.data && equipmentQuery.data.tblEquipmentItemInventory) ||
      [];

    // reduce the equipment query results to one object per resource with a count for each work order and a total count
    const equipmentTagCounts = queryData.reduce((result, resource) => {
      const workOrderTotal =
        (result[resource.ResourceNumber] &&
          result[resource.ResourceNumber][resource.WorkOrderNumber]) ||
        0;
      const resourceTotal =
        (result[resource.ResourceNumber] &&
          result[resource.ResourceNumber].TotalReturned) ||
        0;
      result[resource.ResourceNumber] = {
        ...result[resource.ResourceNumber],
        [resource.WorkOrderNumber]: workOrderTotal + 1,
        TotalReturned: resourceTotal + 1,
      };

      return result;
    }, {});

    // merge counts into the resources table data
    const updatedReturnables = returnables.map(resource => {
      return {
        ...resource,
        ...equipmentTagCounts[resource.ResourceNumber],
      };
    });

    setReturnables(updatedReturnables);

    // build unique sorted array of work order numbers
    const workOrderNumbers = [
      ...new Set(queryData.map(resource => resource.WorkOrderNumber)),
    ].sort();
    // generate column definitions for each work order number in the equipment query data
    const updatedWorkOrderColumns = workOrderNumbers.map(workOrderNumber => {
      return {
        Header: String(workOrderNumber),
        accessor: String(workOrderNumber),
      };
    });

    setWorkOrderColumns(updatedWorkOrderColumns);
  }, [equipmentQuery.data && equipmentQuery?.data?.tblEquipmentItemInventory]);

  const baseColumns = [
    {
      Header: "Resource Name",
      accessor: "Resource",
    },
    ...workOrderColumns,
    {
      Header: "Total Returned",
      accessor: "TotalReturned",
    },
  ];

  const returnableColumns = React.useMemo(() => baseColumns, [
    workOrderColumns,
  ]);

  return (
    <div>
      <h1>Returns</h1>

      {returnables ? (
        <div>
          <hr />
          <div
            id="line-items"
            className="list row"
            style={{ paddingTop: "10px" }}
          >
            <div className="col-md-6 list">
              <h5>RETURNABLE EQUIPMENT</h5>
              <ResultTable columns={returnableColumns} data={returnables} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ReturnsBackend;
