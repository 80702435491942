import React, { useEffect } from "react";
import { Card, message } from "antd";

const WorkOrderCard = ({
  workOrderData,
  status = {},
  workOrderNumberInputRef,
  formComponent,
}) => {
  const { topMessage, centerMessage } = status;

  const cardStyle = {
    backgroundColor:
      workOrderData && !workOrderData?.Cancelled ? "#03ff00" : "red",
    borderRadius: 20,
    overflow: "auto",
  };

  useEffect(() => {
    if (workOrderData?.Cancelled) {
      workOrderNumberInputRef.current?.input?.select();
      message.error("Work Order Cancelled");
    }
  }, [workOrderData]);

  return (
    <Card style={cardStyle}>
      <div style={{ textAlign: "center" }}>
        <span
          style={{
            fontSize: "3vw",
            fontWeight: "bold",
            display: "block",
            lineHeight: 1,
          }}
        >
          {topMessage}
        </span>
        <span
          style={{
            fontSize: "10vw",
            fontWeight: "bold",
            lineHeight: 1,
            fontFamily: "'Titillium Web', sans-serif",
            display: "block",
          }}
        >
          {centerMessage}
        </span>
        <span style={{ display: "block" }}>{formComponent}</span>
      </div>
    </Card>
  );
};

export default WorkOrderCard;
