import { gql } from "@apollo/client";
import { trackingCategories } from ".";

export const GET_LOCATIONS = gql`
  query GetLocation {
    tblLocations {
      items {
        Id
        Name
        Identifier
      }
    }
  }
`;

export const GET_WORKORDER = gql`
  query getWorkOrders($WorkOrderNumber: Int) {
    executeGetWorkOrder(
      WorkOrderNumber: $WorkOrderNumber 
    ) {
      WorkOrderNumber
      JobDate
      TosCompany
      CompanyName
      AccountManager
      ProjectManager
      Originator
      EstimatedHours
      ProposalNumber
      PropertyProtection
      Cancelled
    }
    executeGetWorkOrderResources(
      WorkOrderNumber: $WorkOrderNumber 
    ) {
      WorkOrderNumber
      ResourceNumber
      Resource
      ResourceOrder
      TrackingCategory
      RequestedQuantity
      QuantityFilled
    }
  }
`;

export const GET_RETURNABLE_RESOURCES = gql`
query getReturnableResources {
  vwResources(
    filter: {
      or: [
        { TrackingCategory: { eq: ${trackingCategories.returnableEquipment} } }, 
        { TrackingCategory: { eq: ${trackingCategories.returnableTools} } }
      ]
    }
    orderBy: { ResourceOrder: ASC }
  ) {
    items {
      ResourceNumber
      Resource
      ResourceOrder
      TrackingCategory
    }
  }
}
`;

export const GET_EQUIPMENT = gql`
  query getEquipmentByTag($tagList: String) {
    executeGetEquipmentByTags(tagList: $tagList)
    {
      Id
      TagData
      ResourceNumber
      WorkOrderNumber
    }
  }
`;

export const LOAD_EVENT_START = gql`
  query loadEventStart($LocationId: Int, $WorkOrderNumber: Int) {
    executeLoadEventStart(LocationId: $LocationId, WorkOrderNumber: $WorkOrderNumber) {
      LoadEventId
    }
  }
`;

export const LOAD_EVENT_COMPLETE = gql`
  query loadEventComplete($LoadEventId: Long, $Consumables: String) {
    executeLoadEventComplete(LoadEventId: $LoadEventId ,Consumables: $Consumables) {
      InventoryUpdateCount
    }
  }
`;

export const LOAD_EVENT_CANCEL = gql`
  query loadEventCancel($LoadEventId: Long) {
    executeLoadEventCancel(LoadEventId: $LoadEventId) {
      result
    }
  }
`;

export const GET_LOAD_RESOURCE_COUNTS = gql`
  query loadResourceCounts($LoadEventId: Long) {
    executegetLoadResourceCounts(LoadEventId: $LoadEventId) {
      ResourceNumber
      ResourceCount
      QuantityFilled
    }
  }
`;

export const GET_UNLOAD_RESOURCE_COUNTS = gql`
  query unloadResourceCounts($LoadEventId: Long) {
    executegetUnloadResourceCounts(LoadEventId: $LoadEventId) {
      ResourceNumber
      Resource
      ResourceOrder
      ResourceCount
      WorkOrderNumber
    }
  }
`;
