import {useEffect, useCallback, useState, useRef} from 'react'
import {difference, map, concat} from 'lodash';
import * as mqtt from 'mqtt/dist/mqtt'

const useTagReads = (initialTopic = "") => {
    const [tags, setTags] = useState([]);
    const [status, setStatus] = useState("Not Connected");
    const [client, setClient] = useState();
    // const [topic, setTopic] = useState(initialTopic);
    const topicRef = useRef(initialTopic);
    // const [sub, setSub] = useState('');
    const subRef = useRef('');

    const proto = process.env.REACT_APP_MQTT_PROTOCOL || "ws"
    const host = process.env.REACT_APP_MQTT_HOST || "localhost"
    const port = process.env.REACT_APP_MQTT_PORT || "8083"
    const user = process.env.REACT_APP_MQTT_USERNAME || ""
    const pass = process.env.REACT_APP_MQTT_PASSWORD || ""
    const wsUrl = `${proto}://${host}:${port}/mqtt`
    const options = {
        clientId: "equip-track-" + Math.random().toString(16).substring(2, 8),
        schema: `${proto}"//`,
        port: port,
        username: user,
        password: pass,
        clean: true,
        reconnectPeriod: 1000, // ms
        connectTimeout: 30 * 1000, // ms
    }

    const setTopic = (topic) =>{
        topicRef.current = topic
    }

    if (status === "Not Connected") {
        setStatus('Connecting')
        setClient(mqtt.connect(wsUrl, options))
    }

    const subscribeToTopic = useCallback(() => {
        const qos = {qos: 1}
        const topic = topicRef.current
        // if sub and sub != topic unsubscribe
        if (client) {
            if (subRef.current && subRef.current !== topic) {
                client.unsubscribe(subRef.current, qos, error => {
                    if (error) {
                        console.log('Unsubscribe from topic error', error)
                        return
                    }
                    console.log(`Unsubscribe from topic: ${subRef.current}`)
                    subRef.current = ''
                    setTags([])
                })
            }
            if (topic && subRef.current !== topic) {
                client.subscribe(topic, qos, error => {
                    if (error) {
                        console.log('Subscribe to topic error', error)
                        return
                    }
                    console.log(`Subscribe to topic: ${topic}`)
                    subRef.current = topic
                })
            }
        } else {
            console.log("changeTopic failed client not connected")
        }
    }, [client, subRef, topicRef])

    if (status === "Connected" && subRef.current !== topicRef.current) {
        subscribeToTopic()
    }

    useEffect(() => {
        if (client) {
            if (client._eventsCount && client._eventsCount === 3) {
                client.on("connect", () => {
                    console.log('MQTT connection successful')
                    if (status === "Connected" && subRef.current) subscribeToTopic()
                    setStatus('Connected')
                })
                client.on("error", err => {
                    console.error("Connection error: ", err);
                    client.end();
                    setStatus("Error")
                });
                client.on("reconnect", () => {
                    setStatus("Reconnecting");
                });
                client.on("message", (topic, message) => {
                    const readData = JSON.parse(message.toString());
                    const readTags = map(readData.tags, "epc")
                    
                    setTags(tags => {
                        const newTags = difference(readTags, tags)
                        if (newTags.length)
                            return concat(tags, newTags)
                        else
                            return tags
                    })
                })
            }
        }
    }, [client, status, subRef, subscribeToTopic]);

    return {tags, setTopic}
}

export default useTagReads