import React, { useRef } from "react";
import { Layout } from "antd";
import { useNavigate } from "react-router-dom";

import { trackingCategories, workOrderFormMessage } from "./helpers";
import { WorkOrderDetails } from "./WorkOrderDetails";
import WorkOrderCard from "./WorkOrderCard";
import WorkOrderForm from "./WorkOrderForm";
import { consumableColumns, returnableColumns } from "./constants";
import useUpdatedReturnables from "./hooks/useUpdateReturnables";
import useWorkOrderResources from "./hooks/useWorkOrderResources";
import useResourceActionMethods from "./hooks/useResourceActionMethods";
import { ErrorComponent, LoadingComponent } from "./MiscellaneousUIComponent";
import workOrderstyles from "./styles/index";
import useInitializeWorkOrderData from "./hooks/useInitializeWorkOrderData";
import useLoadResourcesCount from "./hooks/useLoadResourcesCount";
import { EquipmentTable } from "./Table";

const { Content } = Layout;

const WorkOrder = () => {
  const workOrderNumberInputRef = useRef(null);
  const navigate = useNavigate();
  const {
    workOrderNumber,
    setWorkOrderNumber,
    location,
    setLocation,
    getWorkOrders,
    loading,
    error,
    workOrderDetails,
    returnables,
    setReturnables,
    consumables,
    setConsumables,
    loadEventStartQuery,
    eventStartData,
    loadEventId,
    loadResourceCounts,
    resourceCountsData,
    workOrders,
  } = useInitializeWorkOrderData();

  useUpdatedReturnables(returnables, setReturnables, resourceCountsData);
  useWorkOrderResources(
    workOrders,
    setConsumables,
    setReturnables,
    loadEventStartQuery,
    location.Id,
    workOrderNumber,
    trackingCategories
  );
  const {
    loadWorkOrder,
    resetWorkOrder,
    completeWorkOrder,
  } = useResourceActionMethods({
    workOrderNumberInputRef,
    setWorkOrderNumber,
    loadEventId,
    consumables,
    eventStartData,
    loadEventStartQuery,
    getWorkOrders,
  });

  const { updateTableData, skipPageReset } = useResourceActionMethods(
    consumables,
    setConsumables
  );

  useLoadResourcesCount(loadResourceCounts, loadEventId, eventStartData);

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error} />;

  return (
    <Content style={workOrderstyles.containerStyle}>
      <WorkOrderCard
        workOrderNumberInputRef={workOrderNumberInputRef}
        formComponent={
          <WorkOrderForm
            handleNavigateToReturns={() => {
              workOrderDetails &&
                !workOrderDetails.Cancelled &&
                completeWorkOrder();
              navigate("/returns");
            }}
            completeWorkOrder={completeWorkOrder}
            loadWorkOrder={loadWorkOrder}
            resetWorkOrder={resetWorkOrder}
            workOrderNumberInputRef={workOrderNumberInputRef}
            workOrderNumber={workOrderNumber}
            workOrderDetails={workOrderDetails}
            readerOn={workOrderDetails && !workOrderDetails.Cancelled}
            location={location}
          />
        }
        workOrderNumber={workOrderNumber}
        workOrderData={workOrderDetails}
        status={workOrderFormMessage(
          workOrderDetails,
          workOrderNumber || workOrderNumberInputRef.current?.input?.value
        )}
      />

      {workOrderDetails ? (
        <WorkOrderDetails workOrderDetails={workOrderDetails} />
      ) : null}

      {workOrderDetails ? (
        <div style={workOrderstyles.dataAreadContainerStyle}>
          <div
            id="line-items"
            className="list row"
            style={workOrderstyles.rowStyle}
          >
            <EquipmentTable
              title="RETURNABLE EQUIPMENT"
              columns={returnableColumns}
              data={returnables}
            />
            <EquipmentTable
              title="CONSUMABLE EQUIPMENT"
              columns={consumableColumns}
              data={consumables}
              updateTableData={updateTableData}
              skipPageReset={skipPageReset}
            />
          </div>
        </div>
      ) : null}
    </Content>
  );
};

export default WorkOrder;
