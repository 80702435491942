import { message } from "antd";

export const trackingCategories = {
  returnableEquipment: 1,
  returnableTools: 2,
  consumables: 3,
};

export const retrieveDataFromStorage = (key, failedResponseValue = {}) => {
  if (!key || typeof key !== "string") return;
  let responseVal;
  try {
    const response = localStorage.getItem(key);
    responseVal = JSON.parse(response) || failedResponseValue;
  } catch (error) {
    console.log("Error picking data from storage: ", error);
    responseVal = failedResponseValue;
  }
  return responseVal;
};

export function formatDate(dateString) {
  const date = new Date(dateString);

  const options = { 
    weekday: 'long', 
    year: 'numeric', 
    month: 'short', 
    day: '2-digit', 
    hour: '2-digit', 
    minute: '2-digit', 
    hour12: true 
  };

  return date.toLocaleString('en-US', options);
}

export const getColorCode = (remainingQuantity, requestedQuantity) => {
  if (requestedQuantity) {
    if (remainingQuantity > 0) {
      return "yellow";
    }
    else {
      return "green";
    }
  }
}

export const onKeyUpWorkOrderForm = (
  e,
  workOrderNumberInputRef,
  location,
  loadWorkOrder
) => {
  
  if (["Enter", "NumpadEnter"].includes(e.code) && !location?.Id) {
    workOrderNumberInputRef.current?.input?.select();
    return message.error(
      "Location is required to continue. To set it, please navigate to the settings page."
    );
  }

  if (["Enter", "NumpadEnter"].includes(e.code) && !workOrderNumberInputRef?.current?.input?.value) {
    workOrderNumberInputRef.current?.input?.select();
    return message.error(
      "Work Order Number is required before proceeding.",
    );
  }

  if (
    ["Enter", "NumpadEnter"].includes(e.code) &&
    workOrderNumberInputRef.current?.input?.value &&
    location?.Id
  ) {
    
    loadWorkOrder();
  }
};
export const topAlertStatement = (
  returnedDataAvailable,
  workOrderStatus,
  workOrderNumber
) => {
  if (
    !returnedDataAvailable &&
    workOrderStatus === "cancelled" &&
    workOrderNumber
  )
    return "THIS WORK ORDER IS CANCELLED!";
  if (!returnedDataAvailable && workOrderStatus === "filled")
    return "WORK ORDER IS ALREADY FILLED!";
  if (returnedDataAvailable) return "Work Order Equipment Out";
  return "READER OFF - WAIT!";
};

export const workOrderFormMessage = (details, orderNumber) => {
  const formatedMessage = {
    topMessage: "READER OFF - WAIT!!!",
    centerMessage: "Scan Work Order",
  };

  if (details && !details.Cancelled) {
    formatedMessage.topMessage = "READER ON - PROCEED!";
    formatedMessage.centerMessage = orderNumber;
  }

  if (details && details.Cancelled) {
    formatedMessage.topMessage = "THIS WORK ORDER IS CANCELLED!";
    formatedMessage.centerMessage = `CXD - ${orderNumber} - CXD`;
  }
  return formatedMessage;
};
