import React from "react";
import { Form, Input, Button, Space } from "antd";

import {
  ArrowLeftOutlined,
  CloudUploadOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { onKeyUpWorkOrderForm, retrieveDataFromStorage } from "./helpers";

const WorkOrderForm = ({
  handleNavigateToReturns,
  onSubmit,
  readerOn,
  workOrderNumberInputRef,
  completeWorkOrder = () => null,
  loadWorkOrder = () => null,
  resetWorkOrder = () => null,
}) => {
  return (
    <Form
      onFinish={onSubmit}
      style={{ width: "100%", justifyContent: "center" }}
      layout="inline"
    >
      <Form.Item>
        <Button
          type="primary"
          icon={<ArrowLeftOutlined />}
          size="large"
          style={{
            backgroundColor: readerOn ? "#006d75" : "#ff7875",
          }}
          onClick={handleNavigateToReturns}
        >
          Go To Returns
        </Button>
      </Form.Item>
      {!readerOn ? (
        <Form.Item>
          <Input
            type="text"
            id="work-order-number"
            size="large"
            autoFocus
            defaultValue={
              workOrderNumberInputRef?.current?.input?.value ||
              sessionStorage.getItem("workOrderNumber")
            }
            ref={workOrderNumberInputRef}
            allowClear
            onKeyUp={e => {
              onKeyUpWorkOrderForm(
                e,
                workOrderNumberInputRef,
                retrieveDataFromStorage("location", {}),
                readerOn,
                completeWorkOrder,
                loadWorkOrder
              );
            }}
          />
        </Form.Item>
      ) : null}
      <Form.Item>
        <Button
          type="primary"
          icon={<CloudUploadOutlined />}
          style={{ backgroundColor: readerOn ? "green" : "#003a40" }}
          onClick={readerOn ? completeWorkOrder : loadWorkOrder}
          size="large"
        >
          {readerOn ? "Complete Scan" : "Load Work Order"}
        </Button>
        <Space />
      </Form.Item>
      {readerOn ? (
        <Form.Item>
          <Button
            type="primary"
            icon={<StopOutlined />}
            style={{ backgroundColor: "#003a40" }}
            onClick={resetWorkOrder}
            size="large"
          >
            Cancel Scan
          </Button>
        </Form.Item>
      ) : null}
    </Form>
  );
};

export default WorkOrderForm;
