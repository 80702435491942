export const styles = {
  headerTitle: { textAlign: "center", fontSize: "50px", padding: "20px" },
  form: {},
  text: { textAlign: "center" },
  flexContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    margin: "10px 0",
  },
  select: {
    backgroundColor: "#FFFFFF",
    width: "200px",
    borderRadius: 6,
  },
  button: { backgroundColor: "green", color: "white" },
  inputField: { width: "80%" },
};


export const headerStyles = {
  rowStyle: {
    width: "80%",
    padding: 10,
    borderRadius: 10,
    marginLeft: "10%",
  },
  headerContainer: bgColor => ({
    padding: "20px",
    backgroundColor: bgColor,
    borderRadius: 20,
    overflow: "wrap",
  })
}