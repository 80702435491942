import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import "./App.css";
import Nav from "./components/navigation/Nav";
import AdminNav from "./components/navigation/AdminNav";
import ErrorPage from "./error-page";

import WorkOrder from "./components/work_order/WorkOrder";
import ReturnsBackend from "./components/work_order/ReturnsBackend";
import AddLocation from "./components/location/AddLocation";
import Location from "./components/location/Location";
import LocationList from "./components/location/LocationList";
import AddDevice from "./components/device/AddDevice";
import Device from "./components/device/Device";
import DeviceList from "./components/device/DeviceList";
import EditLocation from "./components/location/EditLocation";
import EditDevice from "./components/device/EditDevice";
import Returnables from "./components/returnables/Returnables";
import AppLayout from "./components/layout/AppLayout";
import Home from "./components/navigation/Home";
import Settings from "./components/settings/Settings";
import keycloak from "./services/Keycloak";

const createApolloClient = keycloak => {
  const httpLink = createHttpLink({
    uri:
      process.env.REACT_APP_HASURA_URL ||
      "https://civil-javelin-46.hasura.app/v1/graphql",
  });

  const authLink = setContext(async (_, { headers }) => {
    const TOKEN_MIN_VALIDITY = 250

    // console.log('authLink token', keycloak.token)
    try {
      const tokenValidFor = Math.round(
        keycloak.tokenParsed.exp +
        keycloak.timeSkew -
        new Date().getTime() / 1000,
      )
      if (tokenValidFor < TOKEN_MIN_VALIDITY) {
        const refreshed = await keycloak.updateToken(TOKEN_MIN_VALIDITY)
        // console.debug('Token refreshed ' + refreshed)
        console.warn('Token refreshed ' + refreshed)
      } else {
        // console.warn(`Token not refreshed, still valid for ${tokenValidFor}`)
      }
    } catch (error) {
      console.error('Failed to refresh token', error)
    }
    // console.log('authLink token', keycloak.token)
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return client;
};

function App({ user, keycloak }) {
  const [client] = useState(createApolloClient(keycloak));
  return (
    <ApolloProvider client={client}>
      <div>
        {!keycloak.authenticated && keycloak.login()}
        {/* <div>
          <div style={{display: "flex", alignItems: "center"}}>
            <img src='/rfidCollect.png' alt='rfidCollect logo' /> <b>{`(v${process.env.REACT_APP_UI_VERSION})`}</b>
          </div>
          <div>
          {keycloak.authenticated && user.data.isAdmin ?<AdminNav />: <Nav />}
          </div>
        </div> */}

        <AppLayout>
          <Routes>
            <Route path="/" element={<Home />} ErrorPage={ErrorPage} />
            <Route
              path="/work_order"
              element={<WorkOrder />}
              ErrorPage={ErrorPage}
            />
            <Route
              path="/returns_backend"
              element={<ReturnsBackend />}
              ErrorPage={ErrorPage}
            />
            <Route
              path="/locations"
              element={<LocationList />}
              ErrorPage={ErrorPage}
            />
            <Route
              path="/location"
              element={<AddLocation />}
              ErrorPage={ErrorPage}
            />
            <Route
              path="/location/:id"
              element={<Location />}
              ErrorPage={ErrorPage}
            />
            <Route
              path="/location/edit/:id"
              element={<EditLocation />}
              ErrorPage={ErrorPage}
            />
            <Route
              path="/device/edit/:id"
              element={<EditDevice />}
              ErrorPage={ErrorPage}
            />
            <Route
              path="/devices"
              element={<DeviceList />}
              ErrorPage={ErrorPage}
            />
            <Route
              path="/device"
              element={<AddDevice />}
              ErrorPage={ErrorPage}
            />
            <Route
              path="/device/:id"
              element={<Device />}
              ErrorPage={ErrorPage}
            />
            <Route
              path="/returns"
              element={<Returnables />}
              ErrorPage={ErrorPage}
            />
            <Route
              path="/settings"
              element={<Settings />}
              ErrorPage={ErrorPage}
            />
          </Routes>
        </AppLayout>
      </div>
    </ApolloProvider>
  );
}

export default App;
