import http from '../http-common'
import keycloak from './Keycloak'

const TOKEN_REFRESH_INTERVAL = 30000
const TOKEN_MIN_VALIDITY = 250

async function loadUser() {
  await keycloak.init({ onLoad: 'login-required' })

  const email = keycloak.idTokenParsed.email || keycloak.idTokenParsed.preferred_username
  const user = await AccountService.getAccountByEmail(email)

  return user
}

async function refreshTokens() {
  try {
    const tokenValidFor = Math.round(
            keycloak.tokenParsed.exp +
              keycloak.timeSkew -
              new Date().getTime() / 1000,
          )
    if (tokenValidFor < TOKEN_MIN_VALIDITY) {
      const refreshed = await keycloak.updateToken(TOKEN_MIN_VALIDITY)
      // console.debug('Token refreshed ' + refreshed)
      console.warn('Token refreshed ' + refreshed)
    } else {
      // console.warn(`Token not refreshed, still valid for ${tokenValidFor}`)
    }
  } catch (error) {
    console.error('Failed to refresh token', error)
  }
}

const getAll = () => {
  return http.get('/accounts')
}

const get = (id) => {
  return http.get(`/account/${id}`)
}

const getAccountByEmail = (email_address) => {
  return http.get(`/account/by_email/${email_address}`)
}

const AccountService = {
  getAll,
  get,
  getAccountByEmail,
  loadUser,
  refreshTokens,
  keycloak,
  TOKEN_REFRESH_INTERVAL,
}

export default AccountService
