import React from "react";
import { message } from "antd";

export const workOrderInputValid = (
  workOrderInputVal,
  workOrderNumberInputRef
) => {
  if (!workOrderInputVal) {
    message.error("Work Order number is Required");
    return false;
  }

  if (!/^[0-9]{3,9}$/.test(workOrderInputVal)) {
    workOrderNumberInputRef.current?.input?.select();
    message.error("Invalid work order number.");
    return false;
  }

  return true;
};

export const handleWorkOrderResponse = (data, workOrderNumberInputRef) => {
  if (data.data.executeGetWorkOrder.length === 0) {
    workOrderNumberInputRef.current?.input?.select();
    message.error("Work Order not found");
    return false;
  }

  return true;
};

export const loadWorkOrder = (
  workOrderNumberInputRef,
  setWorkOrderNumber,
  getWorkOrders
) => {
  const workOrderInputVal = workOrderNumberInputRef?.current?.input?.value;

  if (!workOrderInputValid(workOrderInputVal, workOrderNumberInputRef)) return;

  sessionStorage.setItem("workOrderNumber", workOrderInputVal);
  setWorkOrderNumber(workOrderInputVal);

  getWorkOrders({
    variables: {
      WorkOrderNumber: parseInt(workOrderInputVal, 10),
    },
  })
    .then(data => {
      if (handleWorkOrderResponse(data, workOrderNumberInputRef)) return;
    })
    .catch(e => {
      message.error(
        "An error occurred while fetching work order data. Please try again later."
      );
    });
};

export const clearWorkOrder = (
  setWorkOrderNumber,
  getWorkOrders,
  loadEventStartQuery
) => {
  sessionStorage.setItem("workOrderNumber", "");
  setWorkOrderNumber("");
  getWorkOrders({ variables: { WorkOrderNumber: null } });
  loadEventStartQuery({
    variables: { LocationId: null, WorkOrderNumber: null },
  });
};

export const resetWorkOrder = (
  loadEventId,
  loadEventCancelQuery,
  loadEventStartQuery,
  setWorkOrderNumber,
  getWorkOrders
) => {
  if (loadEventId) {
    loadEventCancelQuery({
      variables: { LoadEventId: loadEventId },
    });
    // call start load with null values to clear loadeventquery data from cache
    loadEventStartQuery({
      variables: { LocationId: null, WorkOrderNumber: null },
    });
  }
  return clearWorkOrder(setWorkOrderNumber, getWorkOrders, loadEventStartQuery);
};

export const completeWorkOrder = (
  workOrderNumberInputRef,
  setWorkOrderNumber,
  loadEventId,
  loadEventCompleteQuery,
  consumables,
  eventStartData,
  getWorkOrders,
  loadEventStartQuery
) => {
  setWorkOrderNumber(workOrderNumberInputRef.current?.input.value);
  if (loadEventId) {
    loadEventCompleteQuery({
      variables: {
        LoadEventId: loadEventId,
        Consumables: JSON.stringify(consumables),
      },
    });
  } else {
    console.log("loadEventId not found", eventStartData);
  }

  message.success("Work Order Job completed successfully.");
  return clearWorkOrder(setWorkOrderNumber, getWorkOrders, loadEventStartQuery);
};

export const BASE_QUERY_OPTIONS = { fetchPolicy: "network-only" };
