import { useEffect } from "react";

const allWorkOrderResources = data =>
  data.executeGetWorkOrderResources.map(resource => ({
    ...resource,
    PreviouslyFilled: resource.QuantityFilled,
    Balance: resource.RequestedQuantity - resource.QuantityFilled,
  }));

const workOrderReturnableResources = (workOrderResources, trackingCategories) =>
  workOrderResources.filter(resource =>
    [
      trackingCategories.returnableTools,
      trackingCategories.returnableEquipment,
    ].includes(resource.TrackingCategory)
  );

const workOrderConsumableResources = (workOrderResources, trackingCategories) =>
  workOrderResources.filter(
    resource => resource.TrackingCategory === trackingCategories.consumables
  );

const adjustedWorkOrderConsumableResources = consumableResources =>
  consumableResources.map(resource => {
    const quantityLoaded = resource.QuantityFilled
      ? 0
      : Math.max(resource.RequestedQuantity - resource.QuantityFilled, 0);
    const balance =
      resource.RequestedQuantity - resource.QuantityFilled - quantityLoaded;

    return {
      ...resource,
      QuantityFilled: resource.QuantityFilled,
      QuantityLoaded: quantityLoaded,
      Balance: balance,
    };
  });
const setDefaultResourceValues = (setConsumables, setReturnables) => {
  setConsumables([]);
  setReturnables([]);
  return;
};

const useWorkOrderResources = (
  data,
  setConsumables,
  setReturnables,
  loadEventStartQuery,
  locationId,
  workOrderNumber,
  trackingCategories
) => {
  useEffect(() => {
    if (!data) setDefaultResourceValues(setConsumables, setReturnables);

    if (data) {
      const workOrderResources = allWorkOrderResources(data);

      const returnableResources = workOrderReturnableResources(
        workOrderResources,
        trackingCategories
      );

      const consumableResources = workOrderConsumableResources(
        workOrderResources,
        trackingCategories
      );

      const adjustedConsumableResources = adjustedWorkOrderConsumableResources(
        consumableResources
      );

      const workOrderNotCancelled =
        workOrderNumber &&
        data.executeGetWorkOrder[0] &&
        !data.executeGetWorkOrder[0].Cancelled;
      setConsumables(adjustedConsumableResources);
      setReturnables(returnableResources);

      if (workOrderNotCancelled) {
        loadEventStartQuery({
          variables: {
            LocationId: locationId,
            WorkOrderNumber: parseInt(workOrderNumber, 10),
          },
        });
      }
    }
  }, [
    data,
    setConsumables,
    setReturnables,
    loadEventStartQuery,
    locationId,
    workOrderNumber,
    trackingCategories,
  ]);
};

export default useWorkOrderResources;
