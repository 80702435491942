import React, { useState } from "react";
import { retrieveDataFromStorage } from "../helpers/index";
import {
  GET_LOAD_RESOURCE_COUNTS,
  LOAD_EVENT_START,
} from "../helpers/apolloQueries";
import { GET_WORKORDER } from "../helpers/apolloQueries";
import { useLazyQuery } from "@apollo/client";

const useInitializeWorkOrderData = () => {
  const workOrderFromStorage = retrieveDataFromStorage("workOrderNumber", "");
  const [workOrderNumber, setWorkOrderNumber] = useState(
    workOrderFromStorage || ""
  );

  const locationFromStorage = retrieveDataFromStorage("location", {});
  const [location, setLocation] = useState(locationFromStorage);

  const [
    getWorkOrders,
    { loading, error, data: workOrders },
  ] = useLazyQuery(GET_WORKORDER, { fetchPolicy: "network-only" });
  const workOrderDetails = workOrders && workOrders?.executeGetWorkOrder[0];

  const [returnables, setReturnables] = useState([]);
  const [consumables, setConsumables] = useState([]);

  const [
    loadEventStartQuery,
    { data: eventStartData },
  ] = useLazyQuery(LOAD_EVENT_START, { fetchPolicy: "network-only" });
  const loadEventId = eventStartData?.executeLoadEventStart[0].LoadEventId;

  const [loadResourceCounts, { data: resourceCountsData }] = useLazyQuery(
    GET_LOAD_RESOURCE_COUNTS
  );

  return {
    workOrderNumber,
    setWorkOrderNumber,
    location,
    setLocation,
    getWorkOrders,
    loading,
    error,
    workOrderDetails,
    returnables,
    setReturnables,
    consumables,
    setConsumables,
    loadEventStartQuery,
    eventStartData,
    loadEventId,
    loadResourceCounts,
    resourceCountsData,
    workOrders,
  };
};

export default useInitializeWorkOrderData;
