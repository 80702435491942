import React from "react";
import { ArrowRightOutlined, SettingFilled } from "@ant-design/icons";
import { Col, Layout, Row, theme, Card, Button } from "antd";
import loadTruckVector from "../layout/loadOptOne.jpg";
import { useNavigate } from "react-router-dom";
const { Content } = Layout;
const Home = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  return (
    <Content
      style={{
        margin: "5px 16px",
        height: "80%",
        maxHeight: "80%",
        borderRadius: "12px",
      }}
    >
      <div
        style={{
          padding: 10,
          height: "90vh",
          background: colorBgContainer,
          flex: 1,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row style={{ width: "100%", marginTop: "1%" }} justify="end">
          <Button
            type="primary"
            shape="round"
            icon={<SettingFilled />}
            size={"large"}
            onClick={()=>navigate("/settings")}
            style={{ backgroundColor: "#193664" }}
          >
            Settings
          </Button>
        </Row>
        <Row
          gutter={40}
          justify="center"
          style={{
            width: "80%",
            marginTop: "10%",
          }}
        >
          <Col span={10}>
            <Card
              cover={<img alt="inbound-image" src={loadTruckVector} />}
              hoverable
              onClick={() => navigate("/returns")}
              bordered={true}
              style={{ backgroundColor: "#193664" }}
            >
              <Row gutter={50} style={{ width: "100%" }}>
                <Col span={50}>
                  <b
                    className="header-text-style"
                    style={{ color: "white", fontSize: "x-large" }}
                  >
                    Work Order Inbound
                  </b>
                </Col>
                <Col
                  span={50}
                  className="header-text-style"
                  style={{ color: "white", fontSize: "x-large" }}
                >
                  <ArrowRightOutlined />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={10}>
            <Card
              cover={<img alt="outbound-image" src={loadTruckVector} />}
              onClick={() => navigate("/work_order")}
              hoverable
              bordered={true}
              style={{ backgroundColor: "#193664" }}
            >
              <Row gutter={40} style={{ width: "100%" }}>
                <Col span={50}>
                  <b
                    className="header-text-style"
                    style={{ color: "white", fontSize: "x-large" }}
                  >
                    Work Order Outbound
                  </b>
                </Col>
                <Col
                  span={50}
                  className="header-text-style"
                  style={{ color: "white", fontSize: "x-large" }}
                >
                  <ArrowRightOutlined />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default Home;
