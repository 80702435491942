import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import AccountService from './services/AccountService'

async function startApp() {
  try {
    const user = await AccountService.loadUser()

    localStorage.setItem('company_id', user.data.company_id) 

    // Create a root.
    const root = createRoot(document.getElementById('root'))

    root.render(
      <BrowserRouter>
        <React.StrictMode>
          <App user={user} keycloak={AccountService.keycloak} />
        </React.StrictMode>
      </BrowserRouter>,
    )

    //store authentication tokens in sessionStorage for usage in app
    sessionStorage.setItem('user', user)
    sessionStorage.setItem('company_id', user.data.company_id)
    sessionStorage.setItem('authentication', AccountService.keycloak.token)
    sessionStorage.setItem('refreshToken', AccountService.keycloak.refreshToken)

    setInterval(AccountService.refreshTokens, AccountService.TOKEN_REFRESH_INTERVAL)
  } catch (error) {
    console.log('Could not initialize application :(', error)
  }
}

startApp()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
