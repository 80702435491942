import React from "react";
import { WorkOrderHeader } from "../work_order/WorkOrderHeader";
import { Button, Space } from "antd";
import { CheckSquareOutlined, FlagFilled, StopOutlined, VerticalAlignTopOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const ReturnablesHeader = ({
  unloadingInProgress,
  bgColor,
  location,
  setLocation,
  startUnloading,
  completeUnloading,
  cancelUnloading,
}) => {
  const navigate = useNavigate();
  return (
    <WorkOrderHeader
      isDataShown={unloadingInProgress}
      bgColor={bgColor}
      location={location}
      setLocation={setLocation}
      completeWorkOrder={completeUnloading}
      unloadStarted={false}
      headerTitle={unloadingInProgress ? "" : "READER OFF - WAIT!"}
      headerStatusStatement={
        <h1 id="work-order-number-display">
          {!unloadingInProgress ? "Return Equipment" : "UNLOAD IN PROGRESS"}
        </h1>
      }
      extraFormProps={{
        showWorkOrderNumberInput: false,
        customFlexContainerStyles: {
          display: "block",
          width: "40%",
          marginLeft: "30%",
        },
        AlternativeButtons: (
          <Space style={{ display: "flex", justifyContent: "center"}}>
            <Button
              type="primary"
              onClick={() => {
                unloadingInProgress && completeUnloading();
                navigate("/work_order");
              }}
              size="large"
              icon={<VerticalAlignTopOutlined />}
            >
              Go To Outbound
            </Button>

            <Button
              onClick={() =>
                unloadingInProgress ? completeUnloading() : startUnloading()
              }
              style={{
                color: "white",
                backgroundColor: "green",
              }}
              size="large"
              disabled={!location.Identifier && !unloadingInProgress}
              icon={unloadingInProgress? <CheckSquareOutlined /> :<FlagFilled /> }
            >
              {unloadingInProgress ? "Complete Unload" : "Start Unload"}
            </Button>

            <Button
              onClick={ cancelUnloading }
              style={{
                color: "white",
                backgroundColor: "red",
                display: unloadingInProgress ? "block" : "none",
              }}
              icon={<StopOutlined />}
              size="large"
            >
              Cancel Unload
            </Button>
          </Space>
        ),
        showLoadButton: false,
        showCancelButton: false,
        selectStyles: { width: "100%" },
        formStyle: { display: "block", alignSelf: "center", padding: 6 },
      }}
    />
  );
};

export default ReturnablesHeader;
