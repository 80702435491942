import { EditableCell } from "./Table";
import { getColorCode } from "./helpers";

const generateColumns = ({
  includeEditableCell = false,
  includeBalanceColor = true,
} = {}) => [
  {
    Header: "Resource Name",
    accessor: "Resource",
  },
  {
    Header: "Requested",
    accessor: "RequestedQuantity",
  },
  {
    Header: "Filled",
    accessor: "QuantityFilled",
  },
  {
    Header: "Loading",
    accessor: "QuantityLoaded",
    ...(includeEditableCell && { Cell: EditableCell }),
  },
  {
    Header: "Remaining",
    accessor: "Balance",
    Cell: ({ row }) => {
      const color = includeBalanceColor
        ? getColorCode(row.original.Balance, row.original.RequestedQuantity)
        : null;
      return (
        <div
          style={{
            backgroundColor: color,
            color: color === "green" ? "white" : "black",
            textAlign: "center",
            width: "100%",
          }}
        >
          {row.original.Balance}
        </div>
      );
    },
  },
];

export const returnableColumns = generateColumns();
export const consumableColumns = generateColumns({ includeEditableCell: true });
