import http from '../http-common'

const getAll = () => {
  return http.get('/tag_data_datum')
}

const get = (id) => {
  return http.get(`/tag_data_data/${id}`)
}

const getTagsByWorkOrderNumber = (work_order_number) => {
  return http.get(`/tag_data_data/get_tags_by_work_order_number/${work_order_number}`)
}

const TagDataService = {
  getAll,
  get,
  getTagsByWorkOrderNumber,
}

export default TagDataService
