import React, { useState, useEffect, useMemo } from "react";
import Notiflix from "notiflix";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useTable } from "react-table";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Layout, message } from "antd";

const { Content } = Layout;

const SEARCH_LOCATIONS = gql`
  query SearchLocation($searchName: String) {
    tblLocations(filter: { Name: { contains: $searchName } }) {
      items {
        Id
        Identifier
        Name
      }
    }
  }
`;

const DELETE_LOCATION = gql`
  mutation deleteLocation($id: Int!) {
    deletetblLocation(Id: $id) {
      Id
      Identifier
      Name
    }
  }
`;

const LocationList = props => {
  const [searchName, setSearchName] = useState("");
  const { loading, data, refetch } = useQuery(SEARCH_LOCATIONS, {
    variables: { searchName: searchName },
  });
  const [deleteLocation] = useMutation(DELETE_LOCATION);
  const navigate = useNavigate();

  const onChangeLocationNumber = e => {
    const searchName = e.target.value;
    setSearchName(searchName);
  };

  const location = useLocation();

  const deleteClick = async rowIndex => {
    try {
      await deleteLocation({
        variables: { id: rowIndex },
      });
      await refetch();
      message.success("Location deleted successfully.");
    } catch (e) {
      Notiflix.Notify.failure("There was an error deleting location.");
    }
  };

  const displayData = data?.tblLocations?.items

  const columns = useMemo(
    () => [
      {
        Header: "Location",
        accessor: "Name",
      },
      {
        Header: "Identifier",
        accessor: "Identifier",
      },
      {
        Header: "Actions",
        accessor: "Id",
        Cell: props => {
          const rowIdx = props.row.values.Id;
          const rowData = props.data?.filter(
            element => element["Id"] === rowIdx
          )[0];
          return (
            <div style={{ textAlign: "center" }}>
              <span onClick={() => navigate(`/location/${rowIdx}`)}>
                <i className="fas fa-eye action mr-2">&nbsp;&nbsp;</i>
              </span>

              <span
                onClick={() =>
                  navigate(`/location/edit/${rowIdx}`, {
                    state: { locationData: rowData },
                  })
                }
              >
                <i className="fas fa-pen action mr-2">&nbsp;&nbsp;</i>
              </span>

              <span onClick={() => deleteClick(rowIdx)}>
                <i className="fas fa-trash action">&nbsp;&nbsp;</i>
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };

    fetchData();
  }, [location, displayData, refetch]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: loading ? [] : displayData || [],
  });

  return (
    <Content
      style={{
        margin: "5px 16px",
        height: "95%",
        maxHeight: "95%",
        backgroundColor: "transparent",
        overflow: "auto",
        padding: 12,
      }}
    >
      <div style={{ padding: 20, borderRadius: 20, backgroundColor: "white" }}>
        <div>
          <h4>Locations</h4>
        </div>
        <div className="list row">
          <div className="col-md-8">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search by Name"
                value={searchName}
                onChange={onChangeLocationNumber}
              />
              &nbsp;
              <div className="input-group-append">
                <Link to={"/location"} className="nav-link">
                  <button className="btn btn-outline-secondary" type="button">
                    Create
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-12 list">
            <table
              className="table table-striped table-bordered"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default LocationList;
