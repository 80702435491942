import { useState } from "react";
import Notiflix from "notiflix";
import { useNavigate } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { Divider, Layout, message } from "antd";

const { Content } = Layout;

const INSERT_LOCATION = gql`
  mutation createtblLocation($name: String!, $identifier: String!) {
    createtblLocation(item: { Identifier: $identifier, Name: $name }) {
      Id
      Identifier
      Name
    }
  }
`;

export const LocationForm = ({
  location,
  handleInputChange,
  saveLocation,
  loading,
}) => {
  return (
    <div className="submit-form">
      {
        <div>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              required
              value={location.name}
              onChange={handleInputChange}
              name="name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="identifier">Identifier</label>
            <input
              type="text"
              className="form-control"
              id="identifier"
              required
              value={location.identifier}
              onChange={handleInputChange}
              name="identifier"
            />
          </div>
          <br />
          <button
            onClick={saveLocation}
            className="btn btn-success"
            disabled={loading || !location?.name || !location?.identifier}
          >
            {loading ? "Loading..." : "Submit"}
          </button>
        </div>
      }
    </div>
  );
};
const AddLocation = props => {
  const [insertLocation, { loading }] = useMutation(INSERT_LOCATION);

  const initialLocationState = {
    id: 0,
    name: "",
    identifier: "",
  };
  const navigate = useNavigate();
  const [location, setLocation] = useState(initialLocationState);

  const handleInputChange = event => {
    const { name, value } = event.target;

    setLocation({ ...location, [name]: value });
  };

  const saveLocation = async () => {
    try {
      await insertLocation({
        variables: { name: location.name, identifier: location.identifier },
      });
      message.success("New location added Successfully");
      navigate(`/locations`);
    } catch (e) {
      let message = "Something unxpected happened.";
      if (e?.message.toLowerCase()?.includes("duplicate key")) {
        message = "You can`t add a duplicate location.";
      }
      Notiflix.Notify.failure(message);
    }
  };
  const formProps = { location, handleInputChange, saveLocation, loading };
  return (
    <Content
      style={{
        margin: "5px 16px",
        height: "95%",
        maxHeight: "95%",
        borderRadius: "12px",
        backgroundColor: "transparent",
        overflow: "auto",
        padding: 12,
      }}
    >
      <div style={{ padding: 20, borderRadius: 20, backgroundColor: "white" }}>
        <div>
        <Divider orientation="center"><h4>Add Location</h4></Divider>
        </div>
        <LocationForm {...formProps} />
      </div>
    </Content>
  );
};

export default AddLocation;
