import {
  SaveOutlined,
  StopOutlined,
  UploadOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, Row, Space } from "antd";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  topAlertStatement,
  onKeyUpWorkOrderForm,
  retrieveDataFromStorage,
} from "./helpers/index";
import { headerStyles, styles } from "./styles/headerStyles";

export const WorkOrderForm = ({
  location,
  workOrderNumber = "",
  isDataShown = false,
  completeWorkOrder = () => null,
  loadWorkOrder = () => null,
  resetWorkOrder = () => null,
  showWorkOrderNumberInput = true,
  showLoadButton = true,
  showCancelButton = true,
  workOrderNumberInputRef,
  AlternativeButtons = null,
  formStyle = {},
  workOrderStatus,
}) => {
  const navigate = useNavigate();
  useMemo(() => {
    const location = retrieveDataFromStorage("location");
    if (location) {
      workOrderNumberInputRef?.current?.input.focus();
      workOrderNumberInputRef?.current?.input.select();
    }
  }, [workOrderNumberInputRef]);

  return (
    <div className="form-group" style={{ ...styles.form, ...formStyle }}>
      <Row
        gutter={10}
        justify={"center"}
        style={headerStyles.rowStyle}
      >
        <Space>
          <Col>
            {showWorkOrderNumberInput && (
              <Button
                type="primary"
                onClick={() => {
                  isDataShown &&
                    workOrderStatus !== "cancelled" &&
                    completeWorkOrder();
                  navigate("/returns");
                }}
                icon={<VerticalAlignBottomOutlined />}
                size="large"
              >
                Go To Returns
              </Button>
            )}
          </Col>
          <Col>
            {showWorkOrderNumberInput && !isDataShown && (
              <Input
                type="text"
                id="work-order-number"
                size="large"
                autoFocus
                defaultValue={
                  workOrderNumber || sessionStorage.getItem("workOrderNumber")
                }
                ref={workOrderNumberInputRef}
                allowClear
                onKeyUp={e => {
                  onKeyUpWorkOrderForm(
                    e,
                    workOrderNumberInputRef,
                    location,
                    isDataShown,
                    completeWorkOrder,
                    loadWorkOrder
                  );
                }}
              />
            )}
          </Col>
          <Col>
            {showLoadButton && (
              <Button
                onClick={isDataShown ? completeWorkOrder : loadWorkOrder}
                style={{ backgroundColor: "green" }}
                type="primary"
                icon={!isDataShown ? <UploadOutlined /> : <SaveOutlined />}
                disabled={workOrderStatus === "cancelled" && isDataShown}
                size="large"
              >
                {isDataShown ? "Complete Scan" : `Load Work Order`}
              </Button>
            )}
          </Col>
          <Col>
            {isDataShown && showCancelButton && (
              <Button
                onClick={resetWorkOrder}
                type="primary"
                style={{ backgroundColor: "red" }}
                icon={<StopOutlined />}
                size="large"
              >
                Cancel Scan
              </Button>
            )}
          </Col>
        </Space>
      </Row>
      {AlternativeButtons}
    </div>
  );
};

export const WorkOrderHeader = ({
  headerTitle = "",
  headerStatusStatement = "",
  isDataShown = false,
  workOrderStatus = "",
  workOrderNumber = "",
  bgColor,
  handleInputChange = () => null,
  location,
  setLocation,
  workOrderNumberInputRef,
  completeWorkOrder = () => null,
  loadWorkOrder = () => null,
  resetWorkOrder = () => null,
  workOrderDetails = {},
  extraFormProps = {},
}) => {
  const workOrderFormProps = {
    location,
    setLocation,
    workOrderNumber,
    handleInputChange,
    isDataShown,
    completeWorkOrder,
    loadWorkOrder,
    resetWorkOrder,
    workOrderStatus,
    workOrderNumberInputRef,
    ...extraFormProps,
  };
  return (
    <div
      style={headerStyles.headerContainer(bgColor)}
    >
      <h2 style={styles.text} className="header-text-style">
        {headerTitle ||
          topAlertStatement(
            isDataShown,
            workOrderStatus,
            workOrderNumberInputRef?.current?.input?.value
          )}
      </h2>
      {isDataShown &&
      workOrderNumberInputRef &&
      workOrderStatus !== "cancelled" ? (
        <h1 id="work-order-number-display">
          {workOrderNumberInputRef?.current?.input?.value ||
            sessionStorage.getItem("workOrderNumber")}
        </h1>
      ) : null}
      {headerStatusStatement ? (
        headerStatusStatement
      ) : (
        <h1 id="work-order-number-display">
          {isDataShown && workOrderDetails && !workOrderStatus
            ? workOrderDetails.workOrderNumber
            : isDataShown &&
              workOrderStatus === "cancelled" &&
              workOrderNumberInputRef.current?.input?.value
            ? `CXD - ${workOrderNumberInputRef.current?.input?.value} - CXD`
            : "Scan Work Order"}
        </h1>
      )}
      <WorkOrderForm {...workOrderFormProps} />
    </div>
  );
};
