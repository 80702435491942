import Keycloak from 'keycloak-js'

const keycloak = new Keycloak({
  url: process.env.REACT_APP_AUTH_URL || 'http://localhost:8080/',
  realm: 'development',
  clientId: 'sensor-os',
  scopeId: 'openid',
})

export default keycloak
