import React, { useState, useEffect, useMemo } from "react";
import Notiflix from "notiflix";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useTable } from "react-table";
import { useNavigate, useLocation } from "react-router-dom";
import { Divider, Layout, message } from "antd";

const { Content } = Layout;

const SEARCH_DEVICES = gql`
  query SearchDevice($searchQuery: String) {
    tblDevices(filter: { or: [
          {Name: { contains: $searchQuery } },
          {IpAddress: { contains: $searchQuery } }
        ]}) {
      items {
        Id
        Identifier
        Name
        LocationId
        IpAddress
        Port
        tblLocation {
            Id
            Name
        }
      }
    }
  }
`;

const DELETE_DEVICE = gql`
  mutation deleteDevice($id: Int!) {
    deletetblDevice(Id: $id) {
      Id
      Identifier
      Name
      LocationId
      IpAddress
      Port
    }
  }
`;
const DeviceList = props => {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const { loading, data, refetch } = useQuery(SEARCH_DEVICES, {
    variables: { searchQuery: searchQuery },
  });
  const [deleteDevice] = useMutation(DELETE_DEVICE);
  const navigate = useNavigate();

  const displayData = data?.tblDevices?.items;

  const onChangeDeviceNumber = e => {
    const searchQuery = e.target.value;
    setSearchQuery(searchQuery);
  };

  const deleteDevices = rowIndex => {
    deleteDevice({
      variables: { id: rowIndex },
    })
      .then(() => {
        message.success("Device deleted successfully.");
        refetch();
      })
      .catch(e => {
        message.error("There was an error deleting device.");
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };

    fetchData();
  }, [location, refetch]);

  const columns = useMemo(
    () => [
      {
        Header: "Location",
        accessor: "tblLocation.Name",
      },
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Identifier",
        accessor: "Identifier",
      },
      {
        Header: "IP Address",
        accessor: "IpAddress",
      },
      {
        Header: "Port",
        accessor: "Port",
      },
      {
        Header: "Actions",
        accessor: "Id",
        Cell: props => {
          const rowIdx = props.row.values.Id;
          const rowData = props.data?.filter(
            element => element["Id"] === rowIdx
          )[0];
          return (
            <div style={{ textAlign: "center" }}>
              <span
                onClick={() => 
                  navigate(`/device/edit/${rowIdx}`, {
                    state: { deviceData: rowData },
                  })
                }
              >
                <i className="fas fa-pen action mr-2">&nbsp;&nbsp;</i>
              </span>

              <span onClick={() => deleteDevices(rowIdx)}>
                <i className="fas fa-trash action">&nbsp;&nbsp;</i>
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: loading ? [] : displayData ?? [],
  });

  return (
    <Content
      style={{
        margin: "5px 16px",
        height: "95%",
        maxHeight: "95%",
        borderRadius: "12px",
        backgroundColor: "transparent",
        overflow: "auto",
        padding: 12,
      }}
    >
      <div style={{ padding: 20, borderRadius: 20, backgroundColor: "white" }}>
        <div>
        <Divider orientation="left"><h4>Devices</h4></Divider>
        </div>
        <div className="list row">
          <div className="col-md-8">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search by IP Address"
                value={searchQuery}
                onChange={onChangeDeviceNumber}
              />
              &nbsp;
              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => navigate("/device")}
                  type="button"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12 list">
            <table
              className="table table-striped table-bordered"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default DeviceList;

const res = {
  "errors": [
    {
      "message": "The field `tblLocation` does not exist on the type `Query`.",
      "locations": [
        {
          "line": 2,
          "column": 3
        }
      ],
      "extensions": {
        "type": "Query",
        "field": "tblLocation",
        "responseName": "tblLocation",
        "specifiedBy": "http://spec.graphql.org/October2021/#sec-Field-Selections-on-Objects-Interfaces-and-Unions-Types"
      }
    },
    {
      "message": "The following variables were not used: searchName.",
      "locations": [
        {
          "line": 1,
          "column": 1
        }
      ],
      "extensions": {
        "specifiedBy": "http://spec.graphql.org/October2021/#sec-All-Variables-Used"
      }
    }
  ]
}