import { useState } from "react";
import Notiflix from "notiflix";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { LocationForm } from "./AddLocation";
import { Divider, Layout, message } from "antd";

const { Content } = Layout;

const UPDATE_LOCATION = gql`
  mutation updateLocation($id: Int!, $name: String!, $identifier: String!) {
    updatetblLocation(Id: $id, item:{ Identifier: $identifier, Name: $name }) {
        Id
        Identifier
        Name
    }
  }
`;

const EditLocation = props => {
  const { id } = useParams();
  const { state } = useLocation();
  const [updateLocation, { loading }] = useMutation(UPDATE_LOCATION);

  const initialLocationState = {
    id,
    name: state?.locationData?.Name || "",
    identifier: state?.locationData?.Identifier || "",
  };
  const navigate = useNavigate();
  const [location, setLocation] = useState(initialLocationState);

  const handleInputChange = event => {
    const { name, value } = event.target;

    setLocation({ ...location, [name]: value });
  };

  const saveLocation = async () => {
    try {
      await updateLocation({
        variables: {
          name: location.name,
          identifier: location.identifier,
          id: parseInt(id, 10),
        },
      });
      message.success("Location successfully updated.");
      navigate(`/locations`);
    } catch (e) {
      let message = "Something unxpected happened.";
      if (e?.message.toLowerCase()?.includes("duplicate key")) {
        message = "You can`t add a duplicate location.";
      }
      Notiflix.Notify.failure(message);
    }
  };
  const formProps = { location, handleInputChange, saveLocation, loading };

  return (
    <Content
      style={{
        margin: "5px 16px",
        height: "95%",
        maxHeight: "95%",
        borderRadius: "12px",
        backgroundColor: "transparent",
        overflow: "auto",
        padding: 12,
      }}
    >
      <div style={{ padding: 20, borderRadius: 20, backgroundColor: "white" }}>
        <div>
        <Divider orientation="center"><h4>Update Location</h4></Divider>
        </div>
        <LocationForm {...formProps} />
      </div>
    </Content>
  );
};
export default EditLocation;
