import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { Alert, Layout, Skeleton } from "antd";

const { Content } = Layout;

const GET_RESOURCE = gql`
  query GetResources($Id: Int!) {
    tblLocation(where: { Id: { _eq: $Id } }) {
      Name
      Identifier
      Id
    }
  }
`;

function DisplayResources({ data }) {
  return (
    <>
      <div key={data?.Id}>
        <div>
          <b>Name: </b> {data?.Name}
        </div>
        <div>
          <b>Identifier: </b> {data?.Identifier}
        </div>
        <br />
      </div>
    </>
  );
}

const Location = props => {
  const { id } = useParams();
  const locationId = id ? parseInt(id, 10) : 0;
  const { loading, error, data } = useQuery(GET_RESOURCE, {
    variables: { Id: locationId },
  });

  if (loading)
    return (
      <span style={{ alignSelf: "center" }}>
        <Skeleton active />
      </span>
    );
  if (error)
    return (
      <span
        style={{
          textAlign: "center",
          padding: 60,
          width: "80%",
          alignSelf: "center",
        }}
      >
        <Alert
          message="Error!"
          description={error?.message}
          type="error"
          showIcon
        />
      </span>
    );

  return (
    <Content
      style={{
        margin: "5px 16px",
        height: "95%",
        maxHeight: "95%",
        borderRadius: "12px",
        backgroundColor: "white",
        overflow: "auto",
        padding: 12,
      }}
    >
      <div>
        <h4>Location</h4>
      </div>
      <DisplayResources data={data?.tblLocation[0]} />
    </Content>
  );
};

export default Location;
