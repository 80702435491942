import React, { useEffect, useMemo, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Alert, Empty, Layout, Skeleton, message } from "antd";
import {retrieveDataFromStorage} from "../work_order/helpers/index";
import { LOAD_EVENT_START, LOAD_EVENT_COMPLETE, LOAD_EVENT_CANCEL, GET_UNLOAD_RESOURCE_COUNTS, GET_RETURNABLE_RESOURCES } from "../work_order/helpers/apolloQueries";
import { ResultTable } from "../work_order/Table";
import ReturnablesHeader from "./ReturnablesHeader";
import useReturnablesColumns from "./ReturnablesColumns";

const { Content } = Layout;
const Returnables = () => {
  const locationFromStorage = retrieveDataFromStorage("location", {});
  const [location, setLocation] = useState(locationFromStorage);

  const { error, loading } = useQuery(GET_RETURNABLE_RESOURCES);
  const { columns, setNestedColumns } = useReturnablesColumns();

  const [returnables, setReturnables] = useState([]);


  const [
    loadEventStartQuery,
    {
      // loading: eventStartLoading,
      // error: eventStartError,
      data: eventStartData
    }
  ] = useLazyQuery(LOAD_EVENT_START, {
    fetchPolicy: 'network-only', // Doesn't check cache before making a network request
  });
  const loadEventId = eventStartData?.executeLoadEventStart[0].LoadEventId;
  const bgColor = loadEventId ? "#00FF00" : "#FF0000";

  const [
    loadEventCompleteQuery,
    {
      // loading: eventCompleteLoading,
      // error: eventCompleteError,
      data: eventCompleteData
    }
  ] = useLazyQuery(LOAD_EVENT_COMPLETE, {
    fetchPolicy: 'network-only', // Doesn't check cache before making a network request
  });
  const [
    loadEventCancelQuery,
    {
      // loading: eventCancelLoading,
      // error: eventCancelError,
      data: eventCancelData
    }
  ] = useLazyQuery(LOAD_EVENT_CANCEL, {
    fetchPolicy: 'network-only', // Doesn't check cache before making a network request
  });

  const [
    unloadResourceCounts,
    {
      // loading: resourceCountsLoading,
      // error: resourceCountsError,
      data: resourceCountsData
    }
  ] = useLazyQuery(GET_UNLOAD_RESOURCE_COUNTS);


  useEffect(() => {
    unloadResourceCounts({
      variables: { LoadEventId: loadEventId ? loadEventId : null},  // have to explicitly pass null to avoid error from parameter not being passed
      pollInterval: loadEventId ? 500 : 0
    });
  }, [eventStartData]);

  useMemo(() => {
    const tableData = resourceCountsData?.executegetUnloadResourceCounts.reduce((result, resource) => {
      result[resource.ResourceNumber] = {
        ...result[resource.ResourceNumber],
        ResourceNumber: resource.ResourceNumber,
        Resource: resource.Resource,
        ResourceName: resource.Resource,
        ResourceOrder: resource.ResourceOrder,
        [resource.WorkOrderNumber]: resource.ResourceCount,
      }
      return result;
    }, {})
    setReturnables(Object.values(tableData || {}));

    const workOrderNumbers = [...new Set(resourceCountsData?.executegetUnloadResourceCounts.map(resource => resource.WorkOrderNumber))]
      .filter(workOrderNumber => workOrderNumber !== 'Total')
      .sort()

    const updatedWorkOrderColumns = workOrderNumbers?.map(workOrderNumber => ({
        Header: (
          <div style={{ textAlign: "center", display: "block" }}>
            {workOrderNumber}
          </div>
        ),
        accessor: String(workOrderNumber),
      }));
    setNestedColumns(updatedWorkOrderColumns);

  }, [resourceCountsData]);


  if (loading)
    return (
      <div style={{ textAlign: "center", padding: 20 }}>
        <span style={{ alignSelf: "center" }}>
          <Skeleton active />
        </span>
      </div>
    );
  if (error)
    return (
      <span
        style={{
          textAlign: "center",
          padding: 60,
          width: "80%",
          alignSelf: "center",
        }}
      >
        <Alert
          message="Error!"
          description={error?.message}
          type="error"
          showIcon
        />
      </span>
    );

  const startUnloading = () => {
    loadEventStartQuery({
      variables: {LocationId: location.Id, WorkOrderNumber: null},
    });
  };

  const clearReturns = () => {
    loadEventStartQuery({
      variables: {LocationId: null, WorkOrderNumber: null},
    });
    setReturnables([]);
  };

  const completeUnloading = () => {
    if (loadEventId) {
      loadEventCompleteQuery({
        variables: {LoadEventId: loadEventId, Consumables: null}
      });
    }
    message.success("Unload Completed Successfully.");
    clearReturns();
  };

  const cancelUnloading = () => {
    if (loadEventId) {
      loadEventCancelQuery({
        variables: {LoadEventId: loadEventId}
      });
    }
    clearReturns();
    message.success("Unload Cancelled");
  };

  const returnablesTableProps = {
    // data: returnables?.filter(row => row.TotalReturned) || [],
    data: returnables,
    columns: [
      {
        Header: "Resource Name",
        accessor: "Resource",
        Cell: ({ row }) => (
          <span>{row?.values?.Total ? row.values.Resource : null}</span>
        ),
      },
      ...columns,
      {
        Header: (
          <div style={{ textAlign: "center", display: "block" }}>Total</div>
        ),
        accessor: "Total",
        Cell: ({ row }) => (
          <span>{loadEventId ? row.values.Total : null}</span>
        ),
      },
    ],
  };

  const workOrderHeaderProps = {
    unloadingInProgress : !!loadEventId,
    bgColor,
    location,
    setLocation,
    startUnloading,
    completeUnloading,
    cancelUnloading,
  };

  return (
    <Content
      style={{
        margin: "5px 16px",
        height: "95%",
        maxHeight: "95%",
        backgroundColor: "transparent",
        overflow: "auto",
        padding: 12,
      }}
    >
      <ReturnablesHeader {...workOrderHeaderProps} />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 20,
          marginTop: "2%",
          padding: "2%",
        }}
      >
        {!returnables?.length ? <Empty description="No Equipment Scanned"/> : <ResultTable {...returnablesTableProps} />}
      </div>
    </Content>
  );
};

export default Returnables;
