import React, { useEffect } from "react";

const useLoadResourcesCount = (
  loadResourceCounts,
  loadEventId,
  eventStartData
) => {
  useEffect(() => {
    loadResourceCounts({
      variables: { LoadEventId: loadEventId ? loadEventId : null }, // have to explicitly pass null to avoid error from parameter not being passed
      pollInterval: loadEventId ? 500 : 0,
    });
  }, [eventStartData, loadEventId]);
};

export default useLoadResourcesCount;
