import { Divider, Layout, Row, Select } from "antd";
import { Button, List, Skeleton } from "antd";
import { EyeOutlined, PushpinOutlined, ToolOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { GET_LOCATIONS } from "../work_order/helpers/apolloQueries";
import { retrieveDataFromStorage } from "../work_order/helpers/index";
import { useQuery } from "@apollo/client";

const { Content } = Layout;

const settingsList = [
  {
    name: "Devices",
    route: "/devices",
    description: "Manage all devices.",
    icon: <ToolOutlined style={{ fontSize: "40px" }} />,
  },
  {
    name: "Locations",
    route: "/locations",
    description: "Manage all locations.",
    icon: <PushpinOutlined style={{ fontSize: "40px" }} />,
  },
];

const OtherSettings = () => {
  const navigate = useNavigate();
  return (
    <List
      className="demo-loadmore-list"
      itemLayout="horizontal"
      dataSource={settingsList}
      renderItem={item => (
        <List.Item
          onClick={() => navigate(item.route)}
          style={{ cursor: "pointer" }}
        >
          <Skeleton avatar title={false} loading={item.loading} active>
            <List.Item.Meta
              avatar={item.icon}
              title={item.name}
              description={item.description}
            />
            <Button
              type="primary"
              shape="round"
              icon={<EyeOutlined />}
              onClick={() => navigate(item.route)}
              size={"large"}
              style={{ backgroundColor: "#193664" }}
            >
              Visit
            </Button>
          </Skeleton>
        </List.Item>
      )}
    />
  );
};

const Locations = () => {
  const locationFromStorage = retrieveDataFromStorage("location", {});
  const locationQuery = useQuery(GET_LOCATIONS);
  const locations = locationQuery?.data?.tblLocations?.items.map(location => ({
    value: location.Id,
    label: location.Name,
  }));

  const adjustedLocationFromStorage = {
    value: locationFromStorage?.Id,
    label: locationFromStorage?.Name
  }
  return (
    <Select
      defaultValue={adjustedLocationFromStorage.label}
      size="large"
      style={{ width: "30%", paddingLeft: 20 }}
      allowClear
      options={locations || [locationFromStorage]}
      onChange={(e, value) => {
        const selectedLocation = locationQuery?.data?.tblLocations?.items.find(location => location.Id === value.value)
        localStorage.setItem("location", JSON.stringify(selectedLocation))
      }}
    />
  );
};
const Settings = () => {
  return (
    <Content
      style={{
        margin: "5px 16px",
        height: "95%",
        maxHeight: "95%",
        backgroundColor: "transparent",
        overflow: "auto",
        padding: 12,
      }}
    >
      <div style={{padding: 20, borderRadius: 20, backgroundColor: "white"}}>
        <Divider orientation="left">Dock Location Setting</Divider>
        <Row align="middle" justify="start">
          Current Dock: <Locations />
        </Row>
        <Divider orientation="left">Other Settings</Divider>
        <OtherSettings />
      </div>
    </Content>
  );
};

export default Settings;
