import { Col, List, Row } from "antd";
import React from "react";
import { formatDate } from "./helpers";

export const WorkOrderDetails = ({ workOrderDetails }) => {
  if (!workOrderDetails) return <></>;
  return (
    <div style={{ marginTop: "2%", fontFamily: "'Poppins', sans-serif" }}>
      <List
        size="small"
        bordered
        style={{
          borderRadius: 20,
          backgroundColor: "white",
          padding: 5,
          borderWidth: 0,
        }}
        dataSource={[workOrderDetails]}
        renderItem={item => (
          <Row gutter={10}>
            <Col span={12}>
              <>
                <List.Item>
                  <Row gutter={30}>
                    <Col span={50}>
                      <span style={{ fontSize: 25 }}>Job Date:</span>
                    </Col>
                    <Col span={50} style={{ fontSize: 25 }}>
                      <b>{formatDate(item?.JobDate)}</b>
                    </Col>
                  </Row>
                </List.Item>
                <List.Item>
                  <Row gutter={30}>
                    <Col>
                      <b>TOS Co:</b>
                    </Col>
                    <Col>{item.TosCompany}</Col>
                  </Row>
                </List.Item>
                <List.Item>
                  <Row gutter={30}>
                    <Col>
                      <b>Client:</b>
                    </Col>
                    <Col>{workOrderDetails?.CompanyName}</Col>
                  </Row>
                </List.Item>
                <List.Item>
                  <Row gutter={30}>
                    <Col>
                      <b>Property Protection :</b>
                    </Col>
                    <Col>{workOrderDetails?.PropertyProtection}</Col>
                  </Row>
                </List.Item>
              </>
            </Col>
            <Col span={12}>
              <>
                <List.Item>
                  <Row gutter={10}>
                    <Col>
                      <span style={{ fontSize: 27 }}>Estimated Hours:</span>
                    </Col>
                    <Col style={{ fontSize: 27 }}>
                      <b>{item?.EstimatedHours}</b>
                    </Col>
                  </Row>
                </List.Item>
                <List.Item>
                  <Row gutter={30}>
                    <Col>
                      <b>Account Manager :</b>
                    </Col>
                    <Col>{item.AccountManager}</Col>
                  </Row>
                </List.Item>
                <List.Item>
                  <Row gutter={30}>
                    <Col>
                      <b>Project Manager :</b>
                    </Col>
                    <Col>{workOrderDetails?.ProjectManager}</Col>
                  </Row>
                </List.Item>
                <List.Item>
                  <Row gutter={30}>
                    <Col>
                      <b>WO Originator :</b>
                    </Col>
                    <Col>{workOrderDetails?.Originator}</Col>
                  </Row>
                </List.Item>
              </>
            </Col>
          </Row>
        )}
      />
    </div>
  );
};
