import React, { useEffect, useState } from "react";
import {
  HomeFilled,
  LogoutOutlined,
  SettingFilled,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import keycloak from "../../services/Keycloak";

const { Header } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const menuItems = {
  "1": { key: "home", route: "/", breadCrumb: "" },
  "2": {
    key: "outbound",
    route: "/work_order",
    breadCrumb: "Work Order Outbound",
  },
  "3": { key: "inbound", route: "/returns", breadCrumb: "Work Order Inbound" },
  "4": { key: "settings", route: "/settings", breadCrumb: "Settings" },
};

const AppLayout = props => {
  const [currentKey, setCurrentKey] = useState("1");
  const location = useLocation();
  const navigate = useNavigate();
  const items = [
    getItem("Home", "1", <HomeFilled />),
    getItem("Outbound", "2", <VerticalAlignTopOutlined />),
    getItem("Inbound", "3", <VerticalAlignBottomOutlined />),
    getItem("Settings", "4", <SettingFilled />),
    getItem("Logout", "5", <LogoutOutlined />),
  ];
  useEffect(() => {
    if (["/devices", "/locations", "/settings"].includes(location?.pathname)) {
      setCurrentKey("4");
    }

    if (location?.pathname.includes("returns")) {
      setCurrentKey("3");
    }

    if (location?.pathname.includes("work_order")) {
      setCurrentKey("2");
    }
    if (location?.pathname === "/") {
      setCurrentKey("1");
    }
  }, [location]);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundColor: "#f0f2fc !important",
      }}
    >
      <Layout
        style={{
          maxHeight: "100vh",
          minHeight: "100vh",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "white",
          }}
        >
          <div style={{ flex: 1, width: "30%" }}>
            <img
              src="/rfidCollect.png"
              alt="rfidCollect logo"
              style={{ resizeMode: "contain" }}
            />
          </div>

          <Menu
            theme="light"
            mode="horizontal"
            items={items}
            selectedKeys={[currentKey]}
            onClick={e => {
              if (e.key === "5") {
                return keycloak.logout();
              }
              if (e.key) {
                navigate(`${menuItems[e.key]["route"]}`);
                setCurrentKey(e.key);
              }
            }}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 1,
            }}
          />
          <b>v{process.env.REACT_APP_UI_VERSION}</b>
        </Header>
        {props.children}
      </Layout>
    </Layout>
  );
};
export default AppLayout;
