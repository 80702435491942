import React, { useState, useEffect, useMemo } from 'react'
import { useTable } from 'react-table'
import { useParams } from 'react-router-dom'
import DeviceService from '../../services/DeviceService'
import TagDataService from '../../services/TagDataService'
import dateFormat from 'dateformat'
import { Layout } from "antd";

const { Content } = Layout;

const Device = (props) => {
  const { id, company_id } = useParams()

  const initialDeviceState = {
    id: 0,
    device_number: '',
    device: '',
    active: false,
  }
  const [currentDevice, setCurrentDevice] = useState(initialDeviceState)
  const [deviceTags, setDeviceTags] = useState([])

  const getDevice = (company_id, id) => {
    DeviceService.get(company_id, id)
      .then((response) => {
        setCurrentDevice(response.data)
        TagDataService.getTagsByDeviceNumber(company_id, response.data.device_number).then((response) => {
          setDeviceTags(response.data)
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const completeDevice = (rowIndex) => {
    currentDevice.end_time = new Date()
    currentDevice.active = false
    DeviceService.update(company_id, currentDevice.id, currentDevice).then((response) => {
      window.device.reload(true)
    })
  }

  useEffect(() => {
    if (id && company_id) getDevice(company_id, id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id, id])


  function formatDate(date) {
    return dateFormat(date, 'mm/dd/yyyy - h:MM:ss TT')
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Tag ID',
        accessor: 'sensor_data',
      },
      {
        Header: 'Read Time',
        accessor: 'read_time',
        Cell: (props) => {
          return formatDate(props.value)
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: deviceTags,
  })

  return (
    <Content
      style={{
        margin: "5px 16px",
        height: "95%",
        maxHeight: "95%",
        borderRadius: "12px",
        backgroundColor: "white",
        overflow: "auto",
        padding: 12,
      }}
    >
      
      <div>
        <h4>Device</h4>
      </div>
      <div>
        {currentDevice ? (
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <b>Work Order #</b>
                  </td>
                  <td>{currentDevice.device_number}</td>
                </tr>
                <tr>
                  <td>
                    <b>Device</b>
                  </td>
                  <td>{currentDevice.device.name}</td>
                </tr>
                <tr>
                  <td>
                    <b>Start Time</b>
                  </td>
                  <td>{formatDate(currentDevice.start_time)}</td>
                </tr>
                {currentDevice.active ? (
                  <tr>
                    <td colSpan={2}>
                      <b>Work order is active</b>
                      <div className='input-group-append'>
                        <button className='btn btn-outline-secondary' type='button' onClick={completeDevice}>
                          Complete
                        </button>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>
                      <b>End Time</b>
                    </td>
                    <td>{formatDate(currentDevice.end_time)}</td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className='col-md-12 list'>
              <table className='table table-striped table-bordered' {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div>
            <br />
            <p>No work order selected...</p>
          </div>
        )}
      </div>
    </Content>
  )
}

export default Device

