import React, { useMemo, useState } from "react";

const useReturnablesColumns = () => {
  const [nestedColumns, setNestedColumns] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: (
          <div style={{ textAlign: "center", display: "block" }}>
            {" "}
            Work Orders{" "}
          </div>
        ),
        id: "work-orders",
        columns: nestedColumns,
      },
    ],
    [nestedColumns]
  );

  return {columns, setNestedColumns};
};

export default useReturnablesColumns;
