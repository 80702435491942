import { InputNumber } from "antd";
import React, { useRef } from "react";
import { useTable } from "react-table";
import workOrderstyles from "./styles/index";

export const EditableCell = ({
  value: initialValue,
  row: { index, original },
  column: { id },
  updateTableData, // This is a custom function that we supplied to our table instance
  inputRefs, // cell refs for enter key navigation
}) => {
  const [value, setValue] = React.useState(initialValue || 0);

  const onKeyUp = e => {
    if (["Enter", "NumpadEnter"].includes(e.code)) {
      const nextIndex = index < inputRefs?.current?.length - 1 ? index + 1 : 0;
      inputRefs?.current[nextIndex].focus();
    }
  };

  const onChange = value => {
    setValue(parseInt(value));
  };

  const onFocus = e => {
    inputRefs?.current[index].select();
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateTableData(index, id, value);
  };

  return (
    <InputNumber
      min={0}
      value={parseInt(value, 10) ?? original?.QuantityFilled}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      ref={ref => (inputRefs.current[index] = ref)}
    />
  );
};

export function ResultTable({
  columns,
  data,
  updateTableData = [],
  skipPageReset,
  customClassName = "",
}) {
  const inputRefs = useRef([]);
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows,
  } = useTable({
    columns,
    data,
    updateTableData,
    // use the skipPageReset option to disable page resetting temporarily
    autoResetPage: !skipPageReset,
    inputRefs,
  });
  return (
    <table
      className={`table table-striped table-bordered ${customClassName}`}
      {...getTableProps()}
      style={{ borderRadius: 20 }}
    >
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={row.original.ResourceNumber}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export const EquipmentTable = ({
  title,
  columns,
  data,
  updateTableData,
  skipPageReset,
}) => (
  <div
    className="col-md-6 list"
    style={workOrderstyles.tableDataContainerStyle}
  >
    <h5>{title}</h5>
    <ResultTable
      columns={columns}
      data={data}
      updateTableData={updateTableData}
      skipPageReset={skipPageReset}
    />
  </div>
);
