import http from '../http-common'

const getAll = () => {
  return http.get('/devices/')
}

const get = (id) => {
  return http.get(`/device/${id}`)
}

const getByIdentifier = (identifier) => {
  return http.get(`/device/by_identifier/${identifier}`)
}

const create = (data) => {
  return http.post('/device', data)
}

const update = (id, data) => {
  return http.put(`/device/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/device/${id}`)
}

const LocationService = {
  getAll,
  get,
  getByIdentifier,
  create,
  update,
  remove,
}

export default LocationService
