import { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useMutation, gql, useQuery } from "@apollo/client";
import { Divider, Layout, message } from "antd";

import { DeviceForm } from "./AddDevice";


const { Content } = Layout;

const UPDATE_DEVICE = gql`
  mutation updateDevice(
    $name: String!
    $identifier: String!
    $location_id: Int!
    $ip_address: String!
    $port: Int!
    $id: Int!
  ) {
    updatetblDevice(
      Id: $id,
      item: {
        Identifier: $identifier
        Name: $name
        LocationId: $location_id
        IpAddress: $ip_address
        Port: $port
      }
    ) {
        Id
        Identifier
        Name
        LocationId
        IpAddress
        Port
    }
  }
`;

const GET_LOCATIONS = gql`
  query GetLocation {
    tblLocations {
      items {
        Id
        Identifier
        Name
      }
    }
  }
`;

const EditDevice = props => {
  const { id } = useParams();
  const { state } = useLocation();
  const [updateDevice, { loading }] = useMutation(UPDATE_DEVICE);
  const { data: locationListings } = useQuery(GET_LOCATIONS);

  console.log("dd", locationListings)
  const initialLocationState = {
    id,
    name: state?.deviceData?.Name || "",
    identifier: state?.deviceData?.Identifier || "",
    ip_address: state?.deviceData?.IpAddress || "",
    port: parseInt(state?.deviceData?.Port, 10) || "",
    location_id: parseInt(state?.deviceData?.LocationId, 10) || "",
  };

  const navigate = useNavigate();
  const [device, setDevice] = useState(initialLocationState);

  console.log("device", device, "state", state)

  const handleInputChange = event => {
    const { name, value } = event.target;
    setDevice(device => ({ ...device, [name]: value }));
  };

  const saveDevice = async () => {
    const data = {
      identifier: device.identifier,
      ip_address: device.ip_address,
      port: parseInt(device?.port, 10),
      location_id: parseInt(device?.location_id, 10),
      name: device.name,
      id: parseInt(id, 10),
    };
    try {
      await updateDevice({
        variables: data,
      }).catch(e => console.log("EEEEEEEEE", e));
      message.success("Device successfully updated.");
      navigate(`/devices`);
    } catch (e) {
      // console.log("EEEEEEEEE", e);
      let message = "Something unxpected happened.";
      if (e?.message.toLowerCase()?.includes("duplicate key")) {
        message = "You can`t add a duplicate device.";
      }
      message.error(message);
    }
  };
  const deviceFormProps = {
    device,
    handleInputChange,
    locations: locationListings?.tblLocations?.items ?? [],
    saveDevice,
    loading,
  };

  return (
    <Content
      style={{
        margin: "5px 16px",
        height: "95%",
        maxHeight: "95%",
        borderRadius: "12px",
        backgroundColor: "transparent",
        overflow: "auto",
        padding: 12,
      }}
    >
      <div style={{ padding: 20, borderRadius: 20, backgroundColor: "white" }}>
        <div>
        <Divider orientation="center"><h4>Update Device</h4></Divider>
        </div>
        <div className="submit-form">
          <DeviceForm {...deviceFormProps} />
        </div>
      </div>
    </Content>
  );
};
export default EditDevice;
