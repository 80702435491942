import React, { useEffect } from "react";

const useUpdatedReturnables = (
  returnables,
  setReturnables,
  resourceCountsData
) => {
  useEffect(() => {
    const updatedReturnables = returnables.map(resource => {
      const loadCount = resourceCountsData?.executegetLoadResourceCounts?.find(
        loadCount => loadCount.ResourceNumber === resource.ResourceNumber
      ) || { ResourceCount: 0, QuantityFilled: 0 };

      const {
        ResourceCount: quantityLoaded,
        QuantityFilled: quantityFilled,
      } = loadCount;

      const updatedQuantityFilled = resource.PreviouslyFilled - quantityFilled;
      const updatedBalance = (resource.RequestedQuantity - resource.PreviouslyFilled) + (quantityFilled - quantityLoaded);

      return {
        ...resource,
        QuantityFilled: updatedQuantityFilled,
        QuantityLoaded: quantityLoaded,
        Balance: updatedBalance,
      };
    });

    setReturnables(updatedReturnables);
  }, [resourceCountsData]);
};

export default useUpdatedReturnables;
