import React from "react";
import { Skeleton, Alert } from "antd";

const styles = {
  error: {
    textAlign: "center",
    padding: 60,
    width: "80%",
    alignSelf: "center",
  },
  loader: {
    textAlign: "center",
    padding: 20,
  },
};

export const LoadingComponent = () => (
  <span style={styles.loader}>
    <Skeleton active />
    <Skeleton active />
  </span>
);

export const ErrorComponent = ({ error }) => (
  <span style={styles.error}>
    <Alert
      message="Error!"
      description={error?.message}
      type="error"
      showIcon
    />
  </span>
);
