const workOrderstyles = {
  containerStyle: {
    margin: "5px 16px",
    height: "95%",
    maxHeight: "95%",
    backgroundColor: "transparent",
    overflow: "auto",
    padding: 12,
  },
  dataAreadContainerStyle: { padding: 10, marginTop: "1%" },
  rowStyle: { padding: 10, backgroundColor: "white", borderRadius: 20 },
  tableDataContainerStyle: {
    paddingBottom: "20px",
    fontFamily: "'Poppins', sans-serif",
  },
};
export default workOrderstyles;
