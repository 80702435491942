import { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import Notiflix from "notiflix";
import { useNavigate } from "react-router-dom";
import { Divider, Layout, message } from "antd";

const { Content } = Layout;

export const DeviceForm = ({
  device,
  handleInputChange,
  locations,
  saveDevice,
  loading,
}) => {
  const { port, location_id, name, identifier, ip_address } = device;
  const validData = port && name && identifier && ip_address && location_id;
  return (
    <div>
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          className="form-control"
          id="name"
          required
          value={device.name}
          onChange={handleInputChange}
          name="name"
        />
      </div>
      <div className="form-group">
        <label htmlFor="identfier">Identifier</label>
        <input
          type="text"
          className="form-control"
          id="identifier"
          required
          value={device.identifier}
          onChange={handleInputChange}
          name="identifier"
        />
      </div>
      <div className="form-group">
        <label htmlFor="ip_address">IP Address</label>
        <input
          type="text"
          className="form-control"
          id="ip_address"
          required
          value={device.ip_address}
          onChange={handleInputChange}
          name="ip_address"
        />
      </div>
      <div className="form-group">
        <label htmlFor="port">Port</label>
        <input
          type="string"
          className="form-control"
          id="port"
          required
          value={device.port}
          onChange={handleInputChange}
          name="port"
        />
      </div>
      <div className="form-group">
        <label htmlFor="device">Location</label>
        <select
          className="form-control"
          name="location_id"
          onChange={handleInputChange}
          value={device.location_id}
        >
          {locations.map((option, id) => (
            <option key={option.Id || id} value={option.Id}>
              {option.Name}
            </option>
          ))}
        </select>
      </div>
      <br />
      <button
        onClick={saveDevice}
        disabled={loading || !validData}
        className="btn btn-success"
      >
        {loading ? "Submiting ..." : "Submit"}
      </button>
    </div>
  );
};
const INSERT_DEVICE = gql`
  mutation insertDevice(
    $name: String!
    $identifier: String!
    $location_id: Int!
    $ip_address: String!
    $port: Int!
  ) {
    createtblDevice(
      item:
        {
          Identifier: $identifier
          Name: $name
          LocationId: $location_id
          IpAddress: $ip_address
          Port: $port
        }
    ) {
        Id
        Identifier
        Name
        LocationId
        IpAddress
        Port
    }
  }
`;

const GET_LOCATIONS = gql`
  query GetLocations {
    tblLocations {
      items {
        Id
        Identifier
        Name
      }
    }
  }
`;

const AddDevice = props => {
  const initialDeviceState = {
    id: 0,
    ip_address: "",
    port: "",
    identifier: "",
    location_id: "",
    name: "",
  };
  const [insertDevice, { loading }] = useMutation(INSERT_DEVICE);
  const { data: locationListings } = useQuery(GET_LOCATIONS);
  const navigate = useNavigate();
  const company_id = sessionStorage.getItem("company_id");
  const initLocId = locationListings?.tblLocations?.items[0]?.Id;
  if (initLocId && !initialDeviceState.location_id) {
    initialDeviceState.location_id = initLocId;
  }

  const [device, setDevice] = useState(initialDeviceState);

  const handleInputChange = event => {
    const { name, value } = event.target;

    setDevice({ ...device, [name]: value });
  };

  const saveDevice = () => {
    const data = {
      identifier: device.identifier,
      ip_address: device.ip_address,
      port: parseInt(device?.port, 10),
      location_id: parseInt(device?.location_id, 10),
      company_id: company_id,
      name: device.name,
    };

    insertDevice({
      variables: data,
    })
      .then(() => {
        if (data) {
          newDevice();
          navigate(`/devices`);
          message.success("Device created successfully", {
            timeout: 6000,
          });
        }
      })
      .catch(e => {
        console.log(e);

        let message = "Something unxpected happened.";
        if (e?.message.toLowerCase()?.includes("duplicate key")) {
          message = "You can`t add a duplicate device.";
        }
        Notiflix.Notify.failure(message, { timeout: 6000 });
      });
  };

  const newDevice = () => {
    setDevice(initialDeviceState);
  };

  const deviceFormProps = {
    device,
    handleInputChange,
    locations: locationListings?.tblLocations?.items ?? [],
    saveDevice,
    loading,
  };
  return (
    <Content
      style={{
        margin: "5px 16px",
        height: "95%",
        maxHeight: "95%",
        borderRadius: "12px",
        backgroundColor: "transparent",
        overflow: "auto",
        padding: 12,
      }}
    >
      <div style={{ padding: 20, borderRadius: 20, backgroundColor: "white" }}>
        <div>
        <Divider orientation="center"><h4>Add Devices</h4></Divider>
        </div>
        <div className="submit-form">
          <DeviceForm {...deviceFormProps} />
        </div>
      </div>
    </Content>
  );
};

export default AddDevice;
